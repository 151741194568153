// blueprint defines a:hover with color and underline, which makes primer's link buttons look active
// this doesn't fix the problem (it makes the text black) but it's better than nothing.
a:not(:hover),
a[disabled]:hover,
.bp5-dark a:not(:hover),
.bp5-dark a[disabled]:hover {
  // color: var(--fgColor-link,var(--color-accent-fg));
  color: unset;
  text-decoration: none;
}

a,
a:hover,
.bp5-dark a,
.bp5-dark a:hover {
  color: unset;
}

// :not(.bp5-heading) > a,
// :not(.bp5-heading) > a[disabled]:hover,
// .bp5-dark :not(.bp5-heading) > a,
// .bp5-dark :not(.bp5-heading) > a[disabled]:hover {
//   color: unset;
//   text-decoration: none;
// }

// :not(.bp5-heading) > a,
// :not(.bp5-heading) > a:hover,
// .bp5-dark :not(.bp5-heading) > a,
// .bp5-dark :not(.bp5-heading) > a:hover {
//   color: unset;
// }
